@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-Thin.woff")   format("woff");
  font-weight: 100;
  font-style: '';
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-ThinItalic.woff")   format("woff");
  font-style: 'italic';
  font-weight: 100;
}

@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-Light.woff")   format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-LightItalic.woff")   format("woff");
  font-style: 'italic';
  font-weight: 300;
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-Regular.woff")   format("woff");
  font-style: '';
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-RegularItalic.woff")   format("woff");
  font-style: 'italic';
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-Medium.woff")   format("woff");
  font-weight: 500;
  font-style: '';
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-MediumItalic.woff")   format("woff");
  font-style: 'italic';
  font-weight: 500;
}

@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-Bold.woff")   format("woff");
  font-weight: 700;
  font-style: '';
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-BoldItalic.woff")   format("woff");
  font-style: 'italic';
  font-weight: 700;
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-Black.woff")   format("woff");
  font-weight: 900;
  font-style: '';
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-BlackItalic.woff")   format("woff");
  font-style: 'italic';
  font-weight: 900;
}
@font-face {
  font-family: "BrandonGrotesque";
  src: url("../fonts/BrandonGrotesque/BrandonGrotesque-Regular.woff")   format("woff");
  font-style: '';
}
@font-face {
  font-family: "Enso";
  src: url("../fonts/Enso/enso.ttf");
}

@font-face {
  font-family: "NexaRust";
  font-weight: 900;
  src: url("../fonts/NexaRust/NexaRust-Black.otf") format("otf");
}

@font-face {
  font-family: "NexaRust";
  src: url("../fonts/NexaRust/NexaRust.otf") format("otf");
}